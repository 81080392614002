import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCppMetalStock, fetchCppNaturalStock, fetchMilkyStock, fetchnaturalStock} from "./LdStockAPI";

const initialState = {
  MilkyStock: [],
  NaturalStock: [],
  CppNaturalStock: [],
  CppMetalStock: [],
  status: "idle",
  IsLoding: false,
};

export const fetchMilkyStockAsync = createAsyncThunk(
  "LdpeStock/fetchMilkyStock",
  async ({selectedSize}) => {
    const response = await fetchMilkyStock(selectedSize);
    return response.data;
  }
);
export const fetchNaturalStockAsync = createAsyncThunk(
  "LdpeStock/fetchNaturalStock",
  async ({selectedSize}) => {
    const response = await fetchnaturalStock(selectedSize);
    return response.data;
  }
);
export const fetchCppMetalStockAsync = createAsyncThunk(
  "LdpeStock/fetchcppmetalStock",
  async ({selectedSize}) => {
    const response = await fetchCppMetalStock(selectedSize);
    return response.data;
  }
);
export const fetchCppNaturalStockAsync = createAsyncThunk(
  "LdpeStock/fetchcppNaturalStock",
  async ({selectedSize}) => {
    const response = await fetchCppNaturalStock(selectedSize);
    return response.data;
  }
);

export const ldstockSlice = createSlice({
  name: "Ldstock",
  initialState,
  reducers: {
    increment: (state) => {
      // state.value += 1;  
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMilkyStockAsync.pending, (state) => {
        state.status = "loading";
        state.IsLoding = true;
      })
      .addCase(fetchMilkyStockAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.IsLoding = false;
        state.MilkyStock= action.payload;
      })
      .addCase(fetchCppMetalStockAsync.pending, (state) => {
        state.status = "loading";
        state.IsLoding = true;
      })
      .addCase(fetchCppMetalStockAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.IsLoding = false;
        state.CppMetalStock= action.payload;
      })
      .addCase(fetchCppNaturalStockAsync.pending, (state) => {
        state.status = "loading";
        state.IsLoding = true;
      })
      .addCase(fetchCppNaturalStockAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.IsLoding = false;
        state.CppNaturalStock= action.payload;
      })
      .addCase(fetchNaturalStockAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNaturalStockAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.NaturalStock= action.payload;
      });
  },
});

export const { increment } = ldstockSlice.actions;

export const IsStockLoding= (state) => state.Ldstock.IsLoding;
export const selectMilkyStock= (state) => state.Ldstock.MilkyStock;
export const selectNaturalStock= (state) => state.Ldstock.NaturalStock;
export const selectCppNaturalStock= (state) => state.Ldstock.CppNaturalStock;
export const selectCppMetalStock= (state) => state.Ldstock.CppMetalStock;

export default ldstockSlice.reducer;
