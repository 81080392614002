export function EntryOrder(record) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/add`, {
      method: "POST",
      body: JSON.stringify(record),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function UpdateOrder(record) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/update`, {
      method: "POST",
      body: JSON.stringify(record),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function CompleteOrder(record) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/complete`, {
      method: "POST",
      body: JSON.stringify(record),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchOrderStockData() {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/stockrecord/`, {
      method: "GET",
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchOrderData(record) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/record`, {
      method: "POST",
      body: JSON.stringify(record),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchBlueprintData(search) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/blueprintdata`, {
      method: "POST",
      body: JSON.stringify(search),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function GetDataById(id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/getdatabyid/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchCompaniesName() {
  return new Promise(async (resolve) => {
    console.log("lalit");
    const response = await fetch(`/order/companiesname`, {
      method: "GET",
    });
    const data = await response.json();
    console.log(data);
    resolve({ data });
  });
}
export function fetchJobList(commpany) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/getdatabycompany/${commpany}`, {
      method: "GET",
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function GetDataByCustomId(id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/getdatabycustomid/${id}`, {
      method: "GET",
    });
    const data = await response.json();

    resolve({ data });
  });
}

export function DeleteOrder(id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/order/delete/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    resolve({ data });
  });
}
