import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCppMetalCompany,
  selectCppNaturalCompany,
  selectMilkyCompany,
  selectNaturalCompany,
} from "../recordSlice";
import UpdateRecord from "./update";
import { PencilIcon } from "@heroicons/react/20/solid";

export default function LdbpRecord({ type }) {
  let selectCompany;
  if (type === "milky") {
    selectCompany = selectMilkyCompany;
  } else if (type === "natural") {
    selectCompany = selectNaturalCompany;
  } else if (type === "cppnatural") {
    selectCompany = selectCppNaturalCompany;
  } else if (type === "cppmetal") {
    selectCompany = selectCppMetalCompany;
  }
  const record = useSelector(selectCompany);
  const [updateData, setUpdateData] = useState({});
  const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);

  const handleEdit = (data) => {
    setUpdateData(data);
    setIsUpdateRecordOpen(true);
  };

  const handleCloseUpdateRecord = () => {
    setIsUpdateRecordOpen(false);
  };
  const headers = [
    "Company",
    "Job",
    "Type",
    "Size",
    "Mic",
    "Date",
    "Quantity",
    // "Edit",
  ];
  return (
    <>
      {/* <UpdateRecord
        updateData={updateData}
        type={type}
        isOpen={isUpdateRecordOpen}
        onClose={handleCloseUpdateRecord}
      /> */}
      <div className="shadow-md overflow-hidden border border-gray-300 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`px-6 py-3 text-xs text-center font-bold ${
                    header === "Edit" ? "text-blue-400" : "text-gray-600"
                  } uppercase tracking-wider`}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {record.map((data, ind) => (
              <tr
                className={ind % 2 === 0 ? "bg-white" : "bg-gray-100"}
                key={ind}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-transform: capitalize">
                  {data.company}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center text-transform: capitalize">
                  {data.job}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                  {data.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-mediumx text-center">
                  {data.size}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                  {data.mic}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-mediumx text-center">
                  {data.quantity}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={(e) => {
                      handleEdit(data);
                    }}
                  >
                    <PencilIcon
                      className="-ml-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
