export function fetchPolysterStock(selectedSize) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/polyster/stock/${selectedSize}`,{
      method: 'GET',
    });
    const data = await response.json();
    resolve({ data });
  });
}


export function fetchMetalStock(selectedSize) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/metal/stock/${selectedSize}`,{
      method: 'GET',
    });
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAluminiumStock(selectedSize) {
  return new Promise(async (resolve) => {
    const response = await fetch(`/aluminium/stock/${selectedSize}`,{
      method: 'GET',
    });
    const data = await response.json();
    resolve({ data });
  });
}
