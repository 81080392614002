export function loginUser(userData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch('/auth/login', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'content-type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        const errorData = await response.json();
        reject(errorData.message);
      }
    } catch (error) {
      reject('An error occurred while processing your request.');
    }
  });
}



export function checkAuth() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch("/auth/check");
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}
