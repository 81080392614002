import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EntryOrder,
  CompleteOrder,
  GetDataById,
  fetchOrderData,
  fetchOrderStockData,
  UpdateOrder,
  DeleteOrder,
  GetDataByCustomId,
  fetchBlueprintData,
  fetchCompaniesName,
  fetchJobList,
} from "./orderAPI";

const initialState = {
  printData:{},
  CompletedOrderData:null,
  companiesnameList: [],
  JobDataList: [],
  OrderData: [],
  BlueprintData: [],
  OrderStockData: [],
  status: "idle",
  searchOrderData: [],
  rotationActive: false,
  Isupdated: false,
};

export const fetchOrderStockDataAsync = createAsyncThunk(
  "company/fetchOrderStockData",
  async () => {
    const response = await fetchOrderStockData();
    return response.data;
  }
);

export const fetchOrderDataAsync = createAsyncThunk(
  "company/fetchOrderData",
  async (record) => {
    const response = await fetchOrderData(record);
    return response.data;
  }
);
export const fetchJobListAsync = createAsyncThunk(
  "company/fetchJobList",
  async (commpany) => {
    const response = await fetchJobList(commpany);
    return response.data;
  }
);

export const fetchCompaniesNameAsync = createAsyncThunk(
  "company/fetchCompaniesName",
  async () => {
    const response = await fetchCompaniesName();
    return response.data;
  }
);
export const fetchBlueprintDataAsync = createAsyncThunk(
  "company/fetchblueprintdata",
  async (search) => {
    const response = await fetchBlueprintData(search);
    return response.data;
  }
);



export const EntryOrderAsync = createAsyncThunk(
  "company/EntryOrder",
  async (entrydata) => {
    const response = await EntryOrder(entrydata);
    return response.data;
  }
);
export const GetDataByIdAsync = createAsyncThunk(
  "company/GetDataById",
  async (entrydata) => {
    const response = await GetDataById(entrydata);
    return response.data;
  }
);
export const GetDataByCustomIdAsync = createAsyncThunk(
  "company/GetDataByCustomId",
  async (CustomId) => {
    const response = await GetDataByCustomId(CustomId);
    return response.data;
  }
);
export const CompleteOrderAsync = createAsyncThunk(
  "company/CompleteOrder",
  async (record) => {
    const response = await CompleteOrder(record);
    return response.data;
  }
);
export const DeleteOrderAsync = createAsyncThunk(
  "company/DeleteOrder",
  async (id) => {
    const response = await DeleteOrder(id);
    return response.data;
  }
);
export const UpdateOrderAsync = createAsyncThunk(
  "company/UpdateOrder",
  async (record) => {
    const response = await UpdateOrder(record);
    return response.data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setDataForPrint: (state , action) => { 
      state.printData = action.payload;
    },
    setDataForAddOrder: (state , action) => { 
      state.CompletedOrderData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderStockDataAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchOrderStockDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.OrderStockData = action.payload;
        state.rotationActive = false;
        state.Isupdated = false;
      })
      .addCase(fetchOrderDataAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchOrderDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.OrderData = action.payload;
        state.Isupdated = false;
        state.rotationActive = false;
      })
      .addCase(fetchCompaniesNameAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchCompaniesNameAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.companiesnameList = action.payload;
        state.Isupdated = false;
        state.rotationActive = false;
      })
      .addCase(fetchJobListAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchJobListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.JobDataList = action.payload;
        state.Isupdated = false;
        state.rotationActive = false;
      })
      .addCase(fetchBlueprintDataAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchBlueprintDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.BlueprintData = action.payload;
        state.Isupdated = false;
        state.rotationActive = false;
      })
      .addCase(GetDataByCustomIdAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(GetDataByCustomIdAsync.fulfilled, (state, action) => {
        console.log(action.payload);  
        state.status = "idle";
        state.CompletedOrderData = action.payload;
        state.Isupdated = false;
        state.rotationActive = false;
      })
      
      .addCase(EntryOrderAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(EntryOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.Isupdated = true;
        state.rotationActive = false;

      })
      .addCase(CompleteOrderAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(CompleteOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.Isupdated = true;
      })  
      .addCase(DeleteOrderAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(DeleteOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.Isupdated = true;
      })  
      .addCase(UpdateOrderAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(UpdateOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.Isupdated = true;
      });
  },
});

export const { setDataForPrint } = orderSlice.actions;
export const { setDataForAddOrder } = orderSlice.actions;

export const IsOrderUpdated = (state) => state.order.Isupdated;
export const selectLodingStatus = (state) => state.order.rotationActive;
export const selectPrintData = (state) => state.order.printData;
export const completedOrderData = (state) => state.order.CompletedOrderData;
export const selectOrderData = (state) => state.order.OrderData;
export const companiesnameData = (state) => state.order.companiesnameList;
export const selectBlueprintData = (state) => state.order.BlueprintData;
export const selectOrderStockData = (state) => state.order.OrderStockData;
export const jobDataList = (state) => state.order.JobDataList;

export default orderSlice.reducer;