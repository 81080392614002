import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Squares2X2Icon } from "@heroicons/react/20/solid";
import {
  fetchOrderDataAsync,
  selectOrderData,
  IsOrderUpdated,
  selectLodingStatus,
  setDataForPrint,
  DeleteOrderAsync,
} from "../features/order/orderSlice";
import CompletePage from "../features/order/components/complete";
import OrderInfoPage from "../features/order/components/orderinfo";
import { useNavigate } from "react-router-dom";

export default function OrderRecordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedsearch, setSelectedsearch] = useState("");
  const [selectedSort, setSelectedSort] = useState("Working");

  const record = useSelector(selectOrderData);
  const update = useSelector(IsOrderUpdated);
  const LodingStatus = useSelector(selectLodingStatus);
  const [updateData, setUpdateData] = useState({});
  const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
  const [isInfoRecordOpen, setIsInfoRecordOpen] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [eventType, setEventType] = useState("Update");
  
   // state for delete confirmation
   const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
   const [orderToDelete, setOrderToDelete] = useState(null);
 
 
   //? loding 
  useEffect(() => {
    let intervalId;

    if (LodingStatus) {
      intervalId = setInterval(() => {
        setRotationAngle((prevAngle) => prevAngle + 1);
      }, 1);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      setRotationAngle(0);
      clearInterval(intervalId);
    };
  }, [LodingStatus]);

  const divStyle = {
    transform: `rotate(${rotationAngle}deg)`,
    origin: "center",
    transition: "transform 0.1s linear",
  };

  const handleInfo = (data) => {
    setUpdateData(data);
    setIsInfoRecordOpen(true);
  };
  const handleComplete = (data) => {
    setUpdateData(data);
    setEventType("Complete");
    setIsUpdateRecordOpen(true);
  };
  
  
  const handleEdit = (data) => {
    setUpdateData(data);
    setEventType("Update");
    setIsUpdateRecordOpen(true);
  };
 
  const handlePrint = (data) => {
    console.log(data);
    dispatch(setDataForPrint(data));
    navigate("/print");
  };
  const handleDeleteConfirm = (data) => {
    setOrderToDelete(data);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
    setOrderToDelete(null);
  };

  const handleDeleteConfirmed = () => {
    if (orderToDelete) {
      dispatch(DeleteOrderAsync(orderToDelete.id));
      setIsDeleteConfirmOpen(false);
      setOrderToDelete(null);
    }
  };

  useEffect(() => {
    dispatch(
      fetchOrderDataAsync({ search: selectedsearch, sort: selectedSort })
    );
  }, [dispatch, selectedsearch, selectedSort, update, buttonClicked]);
  const headers = ["Company", "Job", "Size", "Quantity", "status"];
  return (
    <>
       <CompletePage
        eventType={eventType}
        data={updateData}
        isOpen={isUpdateRecordOpen}
        onClose={() => setIsUpdateRecordOpen(false)}
      /> 
      <OrderInfoPage
        data={updateData}
        isOpen={isInfoRecordOpen}
        onClose={() => setIsInfoRecordOpen(false)}
      />
     {isDeleteConfirmOpen && orderToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p className="mb-6">
              Are you sure you want to delete the order for <span className="font-semibold">{orderToDelete.company}</span>? 
              This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleDeleteCancel}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirmed}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white">
        <div>
          <main className="mx-auto max-w px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
              <h1 className="text-4xl font-bold tracking-tight text-blue-800">
                Orders
              </h1>
              <div className="flex justify-end space-x-4">
                <div className="mt-2.5">
                  <select
                    id="EType"
                    name="EType"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedSort}
                    onChange={(e) => setSelectedSort(e.target.value)}
                  >
                    <option value="Working">Working</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
                <div className="mt-2.5">
                  <input
                    onChange={(e) => setSelectedsearch(e.target.value)}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="search"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center">
                  <button
                    style={divStyle}
                    type="button"
                    className="-m-2 ml-5 p-2 text-green-500 hover:text-green-600 sm:ml-7"
                    onClick={() => setButtonClicked(!buttonClicked)}
                  >
                    <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div aria-labelledby="products-heading" className="pb-24 pt-6">
              <div className="shadow-md overflow-hidden border border-gray-300 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      {headers.map((header, index) => (
                        <th
                          key={index}
                          scope="col"
                          className={`px-6 py-3 text-xs text-center font-bold ${
                            header === "Edit"
                              ? "text-blue-400"
                              : "text-gray-600"
                          } uppercase tracking-wider`}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Array.isArray(record) ? (
                      record.map((data, ind) =>
                        selectedSort === data.status ? (
                          <tr
                            className={
                              ind % 2 === 0 ? "bg-white" : "bg-gray-100"
                            }
                            key={ind}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {data.company}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                              {data.job}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                              {data.width} X {data.height}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                              {data.quantity} {data.InKg ? <>Kg</> : <>K</>}
                            </td>
                            {data.status === "Working" ? (
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                                <button
                                  disabled={update}
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-blue-500"
                                  onClick={(e) => {
                                    handleEdit(data);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  disabled={update}
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-green-500"
                                  onClick={(e) => {
                                    handlePrint(data);
                                  }}
                                >
                                  Print
                                </button>
                                <button
                                  disabled={update}
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-red-500"
                                  onClick={(e) => {
                                    handleComplete(data);
                                  }}
                                >
                                  Complete
                                </button>
                                <button
                                  disabled={update}
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-red-500"
                                  onClick={(e) => {
                                    handleDeleteConfirm(data);
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            ) : (
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-blue-500"
                                  onClick={(e) => {
                                    handleInfo(data);
                                  }}
                                >
                                  Info
                                </button>
                              </td>
                            )}
                          </tr>
                        ) : null
                      )
                    ) : (
                      <p>No order data available.</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
