import React from "react";
import { useSelector } from "react-redux";
import {
  selectAluminiumStock,
  selectMetalStock,
  selectPolysterStock,
} from "../pmSlice";
import DataTable from "../../LdStock/components/dataTable";

export default function PolStockTable({ type }) {
  let selectCompany;
  if (type === "metal") {
    selectCompany = selectMetalStock;
  } else if (type === "polyster") {
    selectCompany = selectPolysterStock;
  } else if (type === "aluminium") {
    selectCompany = selectAluminiumStock;
  }
  const stock = useSelector(selectCompany);

  const headers = ["IND", "Size", "Reserved", "Quantity", "BAL QTY"];
  const data = stock.map((polyester, ind) => [
    ind + 1,
    polyester.size,
    polyester.reserved,
    polyester.quantity,
    polyester.quantity + polyester.reserved,
  ]);

  return <DataTable headers={headers} data={data} size={null} />;
}
