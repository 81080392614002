async function fetchData(url, method, requestData) {
  const response = await fetch(url, {
    method,
    body: JSON.stringify(requestData),
    headers: { "content-type": "application/json" },
  });
  const data = await response.json();
  return data;
}

export async function fetchCompanyData(
  Type,
  selectedSort,
  selectedsearch,
  count
) {
  console.log(count);
  const url = `/${Type}/record/`;
  const method = "PATCH";
  const requestData = {
    type: selectedSort,
    search: selectedsearch,
    count:count,
  };
  const data = await fetchData(url, method, requestData);
  return {data : data ,type : Type};
}

export function Entry(data, selectedType) {
  const url = `/${selectedType}/add/`;
  const method = "POST";
  return fetchData(url, method, data);
}

export function UpdateRecord(data, type) {
  const url = `/${type}/update/`;
  const method = "POST";
  return fetchData(url, method, data);
}
