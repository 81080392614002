import React, { useEffect } from "react";
import OrderPage from "./order";
import EntryPage from "./entry";
import {
  IsOrderUpdated,
  fetchOrderStockDataAsync,
  selectOrderStockData,
} from "../features/order/orderSlice";
import { useDispatch, useSelector } from "react-redux";

function Home() {
  const record = useSelector(selectOrderStockData);
  const update = useSelector(IsOrderUpdated);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchOrderStockDataAsync());
  // }, [dispatch, update]);
  const headers = ["Company", "Job","date" ,"metstock", "PATstock", "ldstock", "Type"];

  return (
    <>
      <div className="flex bg-gray-100">
        <div className="w-1/2 p-4 ">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold text-center tracking-tight text-blue-800">
              Order
            </h1>
          </div>
        </div>
        <div className="w-1/2 p-4">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold text-center tracking-tight text-blue-800">
              Entry
            </h1>
          </div>
        </div>
      </div>
      <div className="flex bg-gray-100">
        <div className="w-1/2  border-r border-gray-300">
          <OrderPage />
        </div>
        <div className="w-1/2 ">
          <EntryPage />
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="overflow-x-auto">
          <div className="py-4 align-middle inline-block min-w-full">
            <div className="shadow-md overflow-hidden border border-gray-300 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`px-6 py-3 text-center text-xs font-bold ${
                          header === "BAL QTY"
                            ? "text-blue-400"
                            : "text-gray-600"
                        } uppercase tracking-wider`}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="g-white divide-y divide-gray-200">
                  {record.map((data, ind) => {
                    return (
                      <tr
                        key={ind}
                        className={ind % 2 === 0 ? "bg-white" : "bg-gray-100"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente text-transform: capitalize">
                          {data.company}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente text-transform: capitalize">
                          {data.job}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente text-transform: capitalize">
                        {new Date(data.date).toLocaleDateString("en-GB")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente">
                          <div className="flex">
                            <div className="w-1/2  border-r border-gray-400 text-center">
                              {data.metstock}
                            </div>
                            <div className="w-1/2 text-center">
                              {data.metquantity}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente">
                          <div className="flex">
                            <div className="w-1/2  border-r border-gray-400 text-center">
                              {data.polstock}
                            </div>
                            <div className="w-1/2 text-center">
                              {data.polquantity}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-cente">
                          <div className="flex">
                            <div className="w-1/2  border-r border-gray-400 text-center">
                              {data.ldstock}
                            </div>
                            <div className="w-1/2 text-center">
                              {data.ldquantity}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center uppercase">
                          {data.ldType}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
