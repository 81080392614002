import React from "react";
import DataTable from "./dataTable";

export default function LdDataTable(props) {
  const headers = ["IND", "Mic", "Reserved", "Quantity", "Total QTY"];
  const data = props.stock.mic.map((Ldmic, ind) => [
    ind + 1,
    Ldmic,
    props.stock.reserved[ind],
    props.stock.quantity[ind],
    props.stock.reserved[ind] + props.stock.quantity[ind],
  ]);

  return <DataTable headers={headers} data={data} size={props.stock.size}/>;
}
