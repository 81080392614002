import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCompanyData, Entry, UpdateRecord } from "./recordAPI"; // Adjust the path to your api module

const initialState = {
  PolyesterCompany: [],
  MetalCompany: [],
  AluminiumCompany: [],
  MilkyCompany: [],
  NaturalCompany: [],
  CppNaturalCompany: [],
  CppMetalCompany: [],
  CompanyData: [],
  status: "idle",
  rotationActive: false,
  Isupdated: false,
};

export const fetchCompanyDataAsync = createAsyncThunk(
  "company/fetchCompanyData",
  async ({ Type, selectedSort, selectedsearch,count }) => {
    const response = await fetchCompanyData(Type, selectedSort, selectedsearch,count);
    return response;
  }
);

export const EntryAsync = createAsyncThunk(
  "company/Entry",
  async ({ data, selectedType }) => {
    const response = await Entry(data, selectedType);
    return response;
  }
);

export const UpdateRecordAsync = createAsyncThunk(
  "company/UpdateRecord",
  async ({ data, type }) => {
    // console.log(data);
    const response = await UpdateRecord(data, type);
    return response;
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    increment: (state) => {
      // state.value += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyDataAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(fetchCompanyDataAsync.fulfilled, (state, action) => {
        state.status = "idle";

        const typeToPropertyMap = {
          polyster: "PolyesterCompany",
          metal: "MetalCompany",
          aluminium: "AluminiumCompany",
          milky: "MilkyCompany",
          natural: "NaturalCompany",
          cppnatural: "CppNaturalCompany",
          cppmetal: "CppMetalCompany",
        };

        const { data, type } = action.payload;
        const propertyName = typeToPropertyMap[type];

        if (propertyName) {
          state[propertyName] = data;
        }
        state.rotationActive = false;
        state.Isupdated = false;
      })
      .addCase(EntryAsync.pending, (state) => {
        state.rotationActive = true;
        state.status = "loading";
      })
      .addCase(EntryAsync.fulfilled, (state, action) => {
        state.rotationActive = false;
        state.status = "idle";
      })
      .addCase(UpdateRecordAsync.pending, (state) => {
        state.status = "loading";
        state.rotationActive = true;
      })
      .addCase(UpdateRecordAsync.fulfilled, (state, action) => {
        state.Isupdated = true;
      });
  },
});

export const { increment } = companySlice.actions;

export const IsupdatedStatus = (state) => state.company.Isupdated;
export const selectrotationActive = (state) => state.company.rotationActive;
export const selectCompanyData = (state) => state.company.CompanyData;
export const selectPolyesterCompany = (state) => state.company.PolyesterCompany;
export const selectMetalCompany = (state) => state.company.MetalCompany;
export const selectAluminiumCompany = (state) => state.company.AluminiumCompany;
export const selectMilkyCompany = (state) => state.company.MilkyCompany;
export const selectNaturalCompany = (state) => state.company.NaturalCompany;
export const selectCppNaturalCompany = (state) => state.company.CppNaturalCompany;
export const selectCppMetalCompany = (state) => state.company.CppMetalCompany;

export default companySlice.reducer;
