import { useSelector, useDispatch } from "react-redux";

import { useState, useEffect } from "react";
import { Squares2X2Icon } from "@heroicons/react/20/solid";
import {
  IsStockLoding,
  fetchCppMetalStockAsync,
  fetchCppNaturalStockAsync,
  fetchMilkyStockAsync,
  fetchNaturalStockAsync,
  selectCppMetalStock,
  selectCppNaturalStock,
  selectMilkyStock,
  selectNaturalStock,
} from "../features/LdStock/LdstockSlice";
import {
  fetchAluminiumStockAsync,
  fetchMetalStockAsync,
  fetchPolysterStockAsync,
} from "../features/pol-met/pmSlice";
import LdDataTable from "../features/LdStock/components/LdDataTable";
import PolStockTable from "../features/pol-met/components/polStockTable";

export default function StockPage() {
  const dispatch = useDispatch();
  const milkydata = useSelector(selectMilkyStock);
  const naturaldata = useSelector(selectNaturalStock);
  const cppnaturaldata = useSelector(selectCppNaturalStock);
  const cppmetaldata = useSelector(selectCppMetalStock);
  const LodingStatus = useSelector(IsStockLoding);

  const [selectedSize, setselectedSize] = useState(0);
  const [selectedType, setSelectedType] = useState("all");
  const [polysterList, setPolysterList] = useState(true);
  const [metalList, setMetalList] = useState(true);
  const [aluminiumList, setAluminiumList] = useState(true);
  const [milkyList, setMilkyList] = useState(true);
  const [naturalList, setNaturalList] = useState(true);
  const [cppNaturalList, setCppNaturalList] = useState(true);
  const [cppMetalList, setCppMetalList] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);

  useEffect(() => {
    dispatch(fetchPolysterStockAsync({ selectedSize }));
    dispatch(fetchMetalStockAsync({ selectedSize }));
    dispatch(fetchNaturalStockAsync({ selectedSize }));
    dispatch(fetchCppNaturalStockAsync({ selectedSize }));
    dispatch(fetchCppMetalStockAsync({ selectedSize }));
    dispatch(fetchMilkyStockAsync({ selectedSize }));
    dispatch(fetchAluminiumStockAsync({ selectedSize }));
  }, [dispatch, selectedSize, buttonClicked]);

  const handleType = (e) => {
    const value = e.target.value;
    setSelectedType(value);

    setPolysterList(value === "all" || value === "polyster");
    setMetalList(value === "all" || value === "metal");
    setAluminiumList(value === "all" || value === "aluminium");
    setMilkyList(value === "all" || value === "milky");
    setNaturalList(value === "all" || value === "natural");
    setCppNaturalList(value === "all" || value === "cppnatural");
    setCppMetalList(value === "all" || value === "cppmetal");
  };
  const handalsize = (e) => {
    setselectedSize(+e.target.value);
  };
  useEffect(() => {
    let intervalId;

    if (LodingStatus) {
      intervalId = setInterval(() => {
        setRotationAngle((prevAngle) => prevAngle + 1);
      }, 1);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      setRotationAngle(0);
      clearInterval(intervalId);
    };
  }, [LodingStatus]);

  const divStyle = {
    transform: `rotate(${rotationAngle}deg)`,
    origin: "center",
    transition: "transform 0.1s linear",
  };
  return (
    <>
      <div className="bg-gray-100 ">
        <div>
          <main className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
              <h1 className="text-4xl font-bold tracking-tight text-blue-800">
                Stock
              </h1>
              <div className="flex justify-end space-x-4">
                <div className="mt-2.5">
                  <select
                    id="Type"
                    name="Type"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedType}
                    onChange={handleType}
                  >
                    <option value="all">All</option>
                    <option value="polyster">Polyster</option>
                    <option value="metal">Metal</option>
                    <option value="aluminium">Aluminium</option>
                    <option value="milky">Milky</option>
                    <option value="natural">Natural</option>
                    <option value="cppmetal">cppMetal</option>
                    <option value="cppnatural">cppNatural</option>
                  </select>
                </div>
                <div className="mt-2.5">
                  <input
                    onChange={handalsize}
                    type="number"
                    name="size"
                    id="size"
                    placeholder="size"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center">
                  <button
                    type="button"
                    style={divStyle}
                    className="-m-2 ml-5 p-2 text-green-500 hover:text-green-600 sm:ml-7"
                    onClick={() => {
                      setButtonClicked(!buttonClicked);
                    }}
                  >
                    <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div aria-labelledby="products-heading" className="pb-12 pt-6">
              {/* Polyester */}
              {polysterList && (
                <div className="lg:grid lg:grid-cols-4 gap-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Polyester</h2>
                      <PolStockTable type="polyster" />
                    </div>
                  </div>
                </div>
              )}

              {/* Metal */}
              {metalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Metal</h2>
                      <PolStockTable type="metal" />
                    </div>
                  </div>
                </div>
              )}

              {/* Metal */}
              {aluminiumList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Aluminium</h2>
                      <PolStockTable type="aluminium" />
                    </div>
                  </div>
                </div>
              )}

              {/* Milky */}

              {milkyList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Milky</h2>
                      {milkydata.map((stock) => (
                        <LdDataTable key={stock._id} stock={stock} />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Natural */}
              {naturalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Natural</h2>
                      {naturaldata.map((stock) => (
                        <LdDataTable key={stock._id} stock={stock} />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* CppNatural */}
              {cppNaturalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">CPP Natural</h2>
                      {cppnaturaldata.map((stock) => (
                        <LdDataTable key={stock._id} stock={stock} />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* cppMetal */}
              {cppMetalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">CPP Metal</h2>
                      {cppmetaldata.map((stock) => (
                        <LdDataTable key={stock._id} stock={stock} />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
