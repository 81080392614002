import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/auth/authSlice';
import DataTableReducer from '../features/pol-met/pmSlice';
import LdDataTableReducer from '../features/LdStock/LdstockSlice';
import companyDataReducer from '../features/record/recordSlice';
import orderDataReducer from '../features/order/orderSlice';
import navbarDataReducer from '../features/navbar/navbarSlice';


export const store = configureStore({
  reducer: {
    user: userReducer,
    stock: DataTableReducer,
    Ldstock: LdDataTableReducer,
    company: companyDataReducer,
    order: orderDataReducer,
    navbar: navbarDataReducer,
  },
});
