import Login from "../features/auth/components/login";

function LoginPage() {
  return (
    <>
      <Login />
      {/* <SinUp/> */}
    </>
  );
}

export default LoginPage;
