import { useDispatch, useSelector } from "react-redux";

import { useState, useEffect, useMemo } from "react";
import { Squares2X2Icon } from "@heroicons/react/20/solid";
import PolMetRecord from "../features/record/components/polmet";
import {
  IsupdatedStatus,
  fetchCompanyDataAsync,
  selectrotationActive,
} from "../features/record/recordSlice";
import LdbpRecord from "../features/record/components/ldbp";
import { changeNavbar } from "../features/navbar/navbarSlice";

export default function RecordPage() {
  const dispatch = useDispatch();

  const [selectedsearch, setSelectedsearch] = useState("");
  const [selectedSort, setSelectedSort] = useState("all");
  const [selectedType, setSelectedType] = useState("all");
  const [selectedCount, setselectedCount] = useState("10");
  const [polysterList, setPolysterList] = useState(true);
  const [metalList, setMetalList] = useState(true);
  const [aluminiumList, setAluminiumList] = useState(true);
  const [milkyList, setMilkyList] = useState(true);
  const [naturalList, setNaturalList] = useState(true);
  const [cppNaturalList, setCppNaturalList] = useState(true);
  const [cppMetalList, setCppMetalList] = useState(true);
  const TypeArr = useMemo(
    () => [
      "polyster",
      "metal",
      "milky",
      "natural",
      "aluminium",
      "cppmetal",
      "cppnatural",
    ],
    []
  );
  const [rotationAngle, setRotationAngle] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);

  const rotationActive = useSelector(selectrotationActive);
  const update = useSelector(IsupdatedStatus);

  useEffect(() => {
    let intervalId;

    if (rotationActive) {
      intervalId = setInterval(() => {
        setRotationAngle((prevAngle) => prevAngle + 1);
      }, 1);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      setRotationAngle(0);
      clearInterval(intervalId);
    };
  }, [rotationActive]);

  const divStyle = {
    transform: `rotate(${rotationAngle}deg)`,
    origin: "center",
    transition: "transform 0.1s linear",
  };
  useEffect(() => {
    dispatch(changeNavbar("Record"));
    const count = parseInt(selectedCount); 
    TypeArr.forEach((Type) =>
      dispatch(
        fetchCompanyDataAsync({
          selectedSort,
          selectedsearch,
          Type,
          count
        })
      )
    );
  }, [dispatch, selectedSort, selectedsearch, TypeArr, buttonClicked, update,selectedCount]);
  const handleType = (e) => {
    const value = e.target.value;
    setSelectedType(value);

    setPolysterList(value === "all" || value === "polyster");
    setMetalList(value === "all" || value === "metal");
    setAluminiumList(value === "all" || value === "aluminium");
    setMilkyList(value === "all" || value === "milky");
    setNaturalList(value === "all" || value === "natural");
    setCppNaturalList(value === "all" || value === "cppnatural");
    setCppMetalList(value === "all" || value === "cppmetal");
  };
  const handleCount = (e) => {
    setselectedCount(e.target.value);
  };
  
  return (
    <>
      <div className="bg-gray-100">
        <div>
          <main className="mx-auto max-w px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
              <h1 className="text-4xl font-bold tracking-tight text-blue-800">
                Record
              </h1>
              <div className="flex justify-end space-x-4">
                <div className="mt-2.5">
                  <select
                    id="Type"
                    name="Type"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedCount}
                    onChange={handleCount}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    
                  </select>
                </div>
                <div className="mt-2.5">
                  <select
                    id="Type"
                    name="Type"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedType}
                    onChange={handleType}
                  >
                    <option value="all">All</option>
                    <option value="polyster">Polyster</option>
                    <option value="metal">Metal</option>
                    <option value="aluminium">Aluminium</option>
                    <option value="milky">Milky</option>
                    <option value="natural">Natural</option>
                    <option value="cppmetal">Cpp_Metal</option>
                    <option value="cppnatural">Cpp_Natural</option>
                  </select>
                </div>
                <div className="mt-2.5">
                  <select
                    id="EType"
                    name="EType"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedSort}
                    onChange={(e) => {
                      setSelectedSort(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="Inward">Inward</option>
                    <option value="Outward">Outward</option>
                    <option value="Reserved">Reserved</option>
                  </select>
                </div>
                <div className="mt-2.5">
                  <input
                    onChange={(e) => {
                      setSelectedsearch(e.target.value);
                    }}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="search"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center">
                  <button
                    style={divStyle}
                    type="button"
                    className="-m-2 ml-5 p-2 text-green-500 hover:text-green-600 sm:ml-7"
                    onClick={() => setButtonClicked(!buttonClicked)}
                  >
                    <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div aria-labelledby="products-heading" className="pb-24 pt-6">
              {/* Polyester */}
              {polysterList && (
                <div className="lg:grid lg:grid-cols-4 gap-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Polyester</h2>
                      <PolMetRecord key="polyester" type={"polyster"} />
                    </div>
                  </div>
                </div>
              )}

              {/* Metal */}
              {metalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Metal</h2>
                      <PolMetRecord key="metal" type={"metal"} />
                    </div>
                  </div>
                </div>
              )}

              {/* aluminium */}
              {aluminiumList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Aluminium</h2>
                      <PolMetRecord key="aluminium" type={"aluminium"} />
                    </div>
                  </div>
                </div>
              )}

              {/* Milky */}
              {milkyList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Milky</h2>
                      <LdbpRecord key="milky" type={"milky"} />
                    </div>
                  </div>
                </div>
              )}

              {/* Natural */}
              {naturalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">Natural</h2>
                      <LdbpRecord key="natural" type={"natural"} />
                    </div>
                  </div>
                </div>
              )}
              {/* cppnatural */}
              {cppNaturalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">
                        CPP Natural
                      </h2>
                      <LdbpRecord key="cppnatural" type={"cppnatural"} />
                    </div>
                  </div>
                </div>
              )}
              {/* cppmetal */}
              {cppMetalList && (
                <div className="lg:grid lg:grid-cols-4 gap-8 mt-8">
                  <div className="lg:col-span-4">
                    <div className="border rounded-md shadow-md p-6">
                      <h2 className="text-xl font-semibold mb-4">CPP Metal</h2>
                      <LdbpRecord key="cppmetal" type={"cppmetal"} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
