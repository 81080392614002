import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EntryOrderAsync,
  GetDataByCustomIdAsync,
  companiesnameData,
  completedOrderData,
  fetchCompaniesNameAsync,
  fetchJobListAsync,
  jobDataList,
  selectLodingStatus,
  setDataForAddOrder,
} from "../features/order/orderSlice";

function OrderPage() {
  const [selectedType, setSelectedType] = useState("milky");
  const [selectedPouchType, setSelectedPouchType] = useState("Ribbon");
  const [customId, setCustomId] = useState("");
  const [InKg, setInKg] = useState(true);
  const [Tlayer, setTlayer] = useState("PL");
  const [company, setCompany] = useState("");
  const [cylinderup, setcylinderup] = useState(0);
  const [date, setdate] = useState(new Date().toISOString().substr(0, 10));
  const [height, setheight] = useState(0);
  const [job, setjob] = useState("");
  const [quantity, setquantity] = useState(0);
  const [thickness, setthickness] = useState(0);
  const [width, setwidth] = useState(0);
  const [emptyField, setEmptyField] = useState([]);
  const [quantityIn, setquantityIn] = useState("");
  const dispatch = useDispatch();
  const LodingStatus = useSelector(selectLodingStatus);

  const companiesname = useSelector(companiesnameData);
  const jobData = useSelector(jobDataList);


  const [companyList, setCompanyList] = useState([]);  // New state for company list
  const [filteredCompanies, setFilteredCompanies] = useState([]);  // New state for filtered companies
  const [filteredJobs, setFilteredJobs] = useState([]);
  const handlesubmit = (e) => {
    e.preventDefault();

    const emptyFields = [
      { field: company.trim() === "", name: "Company" },
      { field: job.trim() === "", name: "Job" },
      { field: !InKg && height === 0, name: "Height" },
      { field: width === 0, name: "Width" },
      { field: cylinderup === 0, name: "Cylinder Up" },
      { field: date === "", name: "Date" },
      { field: quantity === 0, name: "Quantity" },
      { field: thickness === 0, name: "Thickness" },
      { field: width === 0, name: "Width" },
    ];

    const emptyFieldNames = emptyFields
      .filter((validation) => validation.field)
      .map((validation) => validation.name);

    if (emptyFieldNames.length > 0) {
      setEmptyField(emptyFieldNames);
      return;
    }

    const data = {
      InKg: InKg,
      Tlayer: Tlayer,
      company: company,
      cylinderup: cylinderup,
      date: date,
      height: height,
      job: job,
      quantity: quantity,
      status: "Working",
      thickness: thickness,
      type: selectedType,
      width: width,
      pouchType: selectedPouchType,
      customId: customId,
    };
    
    dispatch(EntryOrderAsync(data));
    dispatch(setDataForAddOrder(null));
    setnull();
  };

  const setnull= ()=>{
    setCompany("");
      setjob("");
      setSelectedType("milky");
      setSelectedPouchType("Ribbon");
      setInKg(true);
      setTlayer("PL");
      setcylinderup(0);
      setheight(0);
      setquantity(0);
      setthickness(0);
      setwidth(0);  
      setquantityIn("kg");
  }
  const fetchedData = useSelector(completedOrderData);

  useEffect(() => {
    if (fetchedData) {
      setCompany(fetchedData.company || "");
      setjob(fetchedData.job || "");
      setSelectedType(fetchedData.type || "milky");
      setSelectedPouchType(fetchedData.pouchType || "Ribbon");
      setTlayer(fetchedData.Tlayer || "PL");
      setcylinderup(fetchedData.cylinderup || 0);
      setheight(fetchedData.height || 0);
      setthickness(fetchedData.thickness || 0);
      setwidth(fetchedData.width || 0);
      setdate(fetchedData.date || new Date().toISOString().substr(0, 10));
    }
  }, [fetchedData]);
  useEffect(() => {
    if (companiesname && companiesname.length > 0) {
      console.log("update")
      setCompanyList(companiesname);
    }
  }, [companiesname]);

  useEffect(() => {
    dispatch(fetchCompaniesNameAsync());
  }, [dispatch]);
  const handlepachDataFromId = (e)=>{
    e.preventDefault();

    dispatch(GetDataByCustomIdAsync(customId));
  }
  const handleCompanyChange = (e) => {
    const value = e.target.value;
    setCompany(value);
    const filtered = companyList.filter(comp => 
      comp.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };
  
  const handleCompanySelect = (selectedCompany) => {
    setCompany(selectedCompany);
    setFilteredCompanies([]);
    dispatch(fetchJobListAsync(selectedCompany));
  };

  const handleJobChange = (e) => {
    const value = e.target.value;
    setjob(value);
    if (jobData && jobData.length > 0) {
      const filtered = jobData.filter(job => 
        job.job.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredJobs(filtered);
    }
  };

  const handleJobSelect = (selectedJob) => {
    setjob(selectedJob.job);
    setFilteredJobs([]);
    setthickness(selectedJob.thickness);
    setheight(selectedJob.height || 0);
    setTlayer(selectedJob.Tlayer);
    setwidth(selectedJob.width);
    setSelectedType(selectedJob.type);
    setcylinderup(selectedJob.cylinderup);
    setSelectedPouchType(selectedJob.pouchType || "Ribbon");
    setCustomId(selectedJob.customId || "");
  };

  return (
    <>
      <div className="isolate px-6 py-2 sm:py-1 lg:px-8 ">
        <form className="mx-auto mt-10 max-w-xl sm:mt-2">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Company
              </label>
              <div className="mt-2.5 relative">
                <input
                  onChange={handleCompanyChange}
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="off"
                  value={company}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {filteredCompanies.length > 0 && (
                  <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredCompanies.map((filteredCompany, index) => (
                      <li
                        key={index}
                        className="cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                        onClick={() => handleCompanySelect(filteredCompany)}
                      >
                        {filteredCompany}
                      </li>
                    ))}
                  </ul>
                )}
                {emptyField.includes("Company") && (
                  <p className="text-red-500 text-sm mt-1">
                    Company field is required.
                  </p>  
                )}
              </div>
            </div>
            {/* <div className="sm:col-span-2">
              <label
                htmlFor="job"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Job
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setjob(e.target.value)}
                  type="text"
                  name="job"
                  id="job"
                  value={job}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Job") && (
                  <p className="text-red-500 text-sm mt-1">
                    Job field is required.
                  </p>
                )}
              </div>
            </div> */}
            <div className="sm:col-span-2">
              <label
                htmlFor="job"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Job
              </label>
              <div className="mt-2.5 relative">
                <input
                  onChange={handleJobChange}
                  type="text"
                  name="job"
                  id="job"
                  value={job}
                  autoComplete="off"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {filteredJobs.length > 0 && (
                  <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredJobs.map((filteredJob, index) => (
                      <li
                        key={index}
                        className="cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                        onClick={() => handleJobSelect(filteredJob)}
                      >
                        {filteredJob.job}
                      </li>
                    ))}
                  </ul>
                )}
                {emptyField.includes("Job") && (
                  <p className="text-red-500 text-sm mt-1">
                    Job field is required.
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="width"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Width
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setwidth(+e.target.value)}
                  type="number"
                  name="width"
                  id="width"
                  value={width === 0 ? "" : width}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Width") && (
                  <p className="text-red-500 text-sm mt-1">
                    Width field is required.
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="cylinderup"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Cylinder Up
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setcylinderup(+e.target.value)}
                  type="number"
                  name="cylinderup"
                  id="cylinderup"
                  autoComplete="given-name"
                  value={cylinderup === 0 ? "" : cylinderup}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Cylinder Up") && (
                  <p className="text-red-500 text-sm mt-1">
                    Cylinder Up field is required.
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="layer"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Layer
              </label>
              <div className="mt-2.5">
                <select
                  id="layer"
                  name="layer"
                  autoComplete="off"
                  value={Tlayer}
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => {
                    setTlayer(e.target.value);
                  }}
                >
                  <option value="PL">PET + POLY</option>
                  <option value="PML">PET + MET + POLY</option>
                  <option value="PPL">PET + PET + POLY</option>
                  <option value="PAL">PET + ALU + POLY</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor="thickness"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Thickness
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setthickness(+e.target.value)}
                  type="number"
                  name="thickness"
                  id="thickness"
                  value={thickness === 0 ? "" : thickness}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Thickness") && (
                  <p className="text-red-500 text-sm mt-1">
                    Thickness field is required.
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="quantity"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Quantity
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setquantity(+e.target.value)}
                  type="number"
                  name="quantity"
                  id="quantity"
                  autoComplete="family-name"
                  value={quantity === 0 ? "" : quantity}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Quantity") && (
                  <p className="text-red-500 text-sm mt-1">
                    quantity field is required.
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="InKg"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                IN
              </label>
              <div className="mt-2.5">
                <select
                  id="InKg"
                  name="InKg"
                  autoComplete="off"
                  value={quantityIn}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => {
                    setquantityIn(e.target.value);
                    if (e.target.value === "kg") {
                      setInKg(true);
                    } else {
                      setInKg(false);
                    }
                  }}
                >
                  <option value="kg">Kg</option>
                  <option value="pouch">Pouch (K)</option>
                </select>
              </div>
            </div>

            {InKg ? null : (
              <>
                <div>
                  <label
                    htmlFor="PouchType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Pouch Type
                  </label>
                  <div className="mt-2.5">
                    <select
                      id="PouchType"
                      name="PouchType"
                      autoComplete="off"
                      className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={selectedPouchType}
                      onChange={(e) => {
                        setSelectedPouchType(e.target.value);
                      }}
                    >
                      <option value="Ribbon">Ribbon</option>
                      <option value="Central seal">Central seal</option>
                      <option value="Three side seal">Three side seal</option>
                      <option value="Standy">Standy</option>
                      <option value="Standy Zipper">Standy Zipper</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="height"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    height
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setheight(+e.target.value)}
                      type="number"
                      name="height"
                      id="height"
                      value={height === 0 ? "" : height}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {emptyField.includes("Height") && (
                      <p className="text-red-500 text-sm mt-1">
                        height field is required.
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}

            <div>
              <label
                htmlFor="Type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Type
              </label>
              <div className="mt-2.5">
                <select
                  id="Type"
                  name="Type"
                  autoComplete="off"
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                >
                  <option value="milky">Milky</option>
                  <option value="natural">Natural</option>
                  <option value="cpp_natural">CPP-Natural</option>
                  <option value="cpp_metal">CPP-Metal</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Date
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setdate(e.target.value)}
                  type="date"
                  id="date"
                  value={date}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
  <label
    htmlFor="customId"
    className="block text-sm font-semibold leading-6 text-gray-900"
  >
    CustomId
  </label>
  <div className="mt-2.5 flex">
    <input
      onChange={(e) => setCustomId(e.target.value)}
      type="text"
      name="customId"
      id="customId"
      value={customId}
      autoComplete="off"
      className="block w-full rounded-l-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    />
    <button
      type="button"
      onClick={handlepachDataFromId}
      className="bg-indigo-600 text-white px-4 rounded-r-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 text-sm"
    >
      GetData
    </button>
  </div>
</div>
            
          </div>
          <div className="mt-10">
            <button
              type="button"
              onClick={handlesubmit}
              disabled={LodingStatus}
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {LodingStatus ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="font-medium ml-2">Processing...</span>{" "}
                </div>
              ) : (
                <>Add Order</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default OrderPage;
