import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { selectPrintData } from "../orderSlice";

const DataBox = ({
  companyName,
  jobName,
  orderQuantity,
  pouchType,
  mic,
  combination,
  date,
  inkg,
  type,
  instruction,
}) => {
  return (
    <>
      <div className="p-5"></div>
      <div className=" p-3 rounded-sm border border-gray-800">
        <div className="grid grid-cols-2 gap-4 mb-4 justify-center">
          <div className="flex">
            <div className="font-semibold mb-1 px-2 text-lg">Company Name:</div>
            <div className="text-blue-700 text-lg text-transform: capitalize">
              {companyName}
            </div>
          </div>
          <div className="flex">
            <div className="font-semibold mb-1 px-2 text-lg">Job Name:</div>
            <div className="text-blue-700 text-lg text-transform: capitalize">
              {jobName}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 justify-center">
          <div className="flex">
            <div className="font-semibold mb-1 px-2">Combination:</div>
            <div className="text-blue-700">
              <div className="grid grid-cols-2">
                {combination && (
                  <div className="flex pr-1">
                    {combination === "PPL" ? (
                      <> PET + PET + LDPE</>
                    ) : combination === "PML" ? (
                      <>PET + MET + LDPE</>
                    ) : combination === "PAL" ? (
                      <>PET + ALU + LDPE</>
                    ) : type === "cpp_natural" || type === "cpp_metal" ? (
                      <>PET + CPP</>
                    ) : (
                      <>PET + LDPE</>
                    )}
                  </div>
                )}
                <div className="flex pl-2">
                  {combination === "PAL" || combination === "PML" ? (
                    <>12+12+</>
                  ) : (
                    <>12+</>
                  )}{" "}
                  {mic}
                </div>
              </div>
            </div>
          </div>
          <div className="flex pl-2">
            <div className="font-semibold mb-1 px-2">Instruction:</div>
            <div className="text-blue-700">{instruction}</div>
          </div>
        </div>
        <div className="grid grid-cols-3  justify-center">
          <div className="flex">
            <div className="font-semibold mb-1 px-2">Quantity:</div>
            <div className="text-blue-700">
              {orderQuantity}
              {inkg ? <> Kg</> : <>,000 NOS</>}
            </div>
          </div>

          {!inkg && (
            <div className="flex">
              <div className="font-semibold mb-1 px-2">Pouch Type:</div>
              <div className="text-blue-700">{pouchType}</div>
            </div>
          )}
          <div className={`flex  ${!inkg ? "px-2" : "px-36"}`}>
            <div className={`font-semibold mb-1 `}>Date:</div>
            <div className="text-blue-700 px-2">
              {new Date(date).toLocaleDateString("en-GB")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const InputFormetBox = ({ headers }) => {
  return (
    <>
      <div className="bg-white p-1 rounded-md">
        <div className="grid grid-cols-3 gap-4 mb-4 justify-center ">
          <div className="col-span-3">
            <div className="flex border-r border-l border-t border-gray-800 ">
              <div className="border-r border-gray-800 p-2 flex-grow text-left font-semibold">
                OPERATOR
              </div>
              <div className="border-r border-gray-800 p-2 flex-grow text-left font-semibold">
                MACHINE
              </div>
              <div className="p-2 flex-grow text-left font-semibold">DATE</div>
            </div>
            <table className="w-full border">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="border border-gray-800  px-4 py-2"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 4 }).map((_, index) => (
                  <tr key={index}>
                    {headers.map((_, ind) => (
                      <td
                        key={ind + 10}
                        className="border border-gray-800 px-4 py-5"
                      ></td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
const CardDefault = React.forwardRef((props, ref) => {
  const data = useSelector(selectPrintData);
  return (
    <div ref={ref}>
      {data && (
        <>
          {" "}
          <DataBox
            companyName={data.company}
            jobName={data.job}
            orderQuantity={data.quantity}
            pouchType={data.pouchType}
            mic={data.ldMic}
            type={data.type}
            combination={data.Tlayer}
            date={data.date}
            inkg={data.InKg}
            instruction={props.instruction}
          />
          <div className="p-5"></div>
          <div className=" p-4 rounded-md">
            <div className="flex justify-center items-center">
              <h1 className="text-2xl font-bold text-gray-950">PRINTING</h1>
            </div>
            <div className="grid grid-cols-4 gap-4 mb-4 justify-center">
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Meter:</div>
                <div className="text-blue-700 text-xl">{data.meter}</div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Size:</div>
                <div className="text-blue-700 text-xl">
                  {data.polysterSize}mm
                </div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Quantity:</div>
                <div className="text-blue-700 text-xl">
                  {data.Tlayer === "PPL"
                    ? data.polysterQuantity / 2
                    : data.polysterQuantity}{" "}
                  KG
                </div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">ClQ:</div>
                <div className="text-blue-700 text-xl">
                  {data.customId}
                </div>
              </div>
            </div>
            <InputFormetBox
              headers={[
                "PLAIN",
                "PRINTED",
                "METER",
                "WAST",
                "INK",
                "REMARK",
                "BALANCE",
              ]}
            />
          </div>
          <div className="p-2"></div>
          <div className=" p-4 rounded-md">
            <div className="flex justify-center items-center">
              <h1 className="text-2xl font-bold text-gray-950">LAMINATION</h1>
            </div>
            <div className="grid grid-cols-4 gap-4 mb-4  p-2 justify-center">
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Size:</div>
                <div className="text-blue-700 text-xl">{data.ldSize}mm</div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Mic:</div>
                <div className="text-blue-700 text-xl">{data.ldMic}</div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">Quantity:</div>
                <div className="text-blue-700 text-xl">
                  {data.ldQuantity} KG
                </div>
              </div>
              <div className="flex">
                <div className="font-semibold mb-1 px-2 text-xl">
                  {data.type === "cpp_natural" || data.type === "cpp_metal"
                  ? <>CPP:</> : <>LDPE:</>}</div>
                <div className="text-blue-700 text-xl">
                  {data.type === "cpp_natural" || data.type === "cpp_metal"
                    ? data.type.substring(4).charAt(0).toUpperCase() +
                      data.type.slice(5)
                    : data.type.charAt(0).toUpperCase() + data.type.slice(1)}
                </div>
              </div>
            </div>

            <InputFormetBox
              headers={[
                "POLYSTER",
                "METPET/FOIL",
                "LDPE",
                "ADHESIVE",
                "OUTPUT WT",
              ]}
            />
          </div>
          <div className="p-5"></div>
          <div>
            <div className="p-7"></div>
            <div className=" p-2 rounded-md">
              <div className="flex justify-center items-center">
                <h1 className="text-2xl font-bold text-gray-950">SLITTING</h1>
              </div>

              <InputFormetBox
                headers={["WEIGHT", "WASTE", "METER", "TRIMMING"]}
              />
            </div>
            <div className="p-5"></div>

            <div className=" p-4 rounded-md">
              <div className="flex justify-center items-center">
                <h1 className="text-2xl font-bold text-gray-950">POUCHING</h1>
              </div>

              <InputFormetBox headers={["DATE", "Quantity"]} />
            </div>
          </div>
          <div className="p-14"></div>
          <div className="flex justify-end items-center mt-4">
            <div className="pr-20">
              <div className="border-t border-gray-800 "></div>
              <div className="ml-4 text-lg font-semibold pr-7">
                Mahendra Patel
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const PrintButton = () => {
  const componentRef = useRef();
  const [instruction, setInstruction] = useState("");
  return (
    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 text-center">
      <div className="py-3">
        <div className="mt-2.5">
          <input
            onChange={(e) => setInstruction(e.target.value)}
            type="text"
            name="instruction"
            id="instruction"
            placeholder="Enter your instruction"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 sm:text-sm"
          />
        </div>
        <ReactToPrint
          trigger={() => (
            <button className="mt-3 inline-flex items-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <CardDefault ref={componentRef} instruction={instruction} />
    </div>
  );
};

export default PrintButton;
