import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Squares2X2Icon } from "@heroicons/react/20/solid";
import {
  fetchBlueprintDataAsync,
  IsOrderUpdated,
  selectBlueprintData,
  selectLodingStatus,
} from "../features/order/orderSlice";

export default function BlueprintRecordPage() {
  const dispatch = useDispatch();
  const [selectedsearch, setSelectedsearch] = useState("");

  const record = useSelector(selectBlueprintData);
  const update = useSelector(IsOrderUpdated);
  const LodingStatus = useSelector(selectLodingStatus);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  
  useEffect(() => {
    let intervalId;

    if (LodingStatus) {
      intervalId = setInterval(() => {
        setRotationAngle((prevAngle) => prevAngle + 1);
      }, 1);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      setRotationAngle(0);
      clearInterval(intervalId);
    };
  }, [LodingStatus]);

  const divStyle = {
    transform: `rotate(${rotationAngle}deg)`,
    origin: "center",
    transition: "transform 0.1s linear",
  };


  const handleDelete = (data) => {
  };

  useEffect(() => {
    dispatch(fetchBlueprintDataAsync({ search: selectedsearch }));
  }, [dispatch, selectedsearch, update, buttonClicked]);

  const headers = ["Company", "Job", "Custom ID", "Actions"];

  return (
    <>
      <div className="bg-white">
        <div>
          <main className="mx-auto max-w px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
              <h1 className="text-4xl font-bold tracking-tight text-blue-800">
              CustomId
              </h1>
              <div className="flex justify-end space-x-4">
                <div className="mt-2.5">
                  <input
                    onChange={(e) => setSelectedsearch(e.target.value)}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="search"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center">
                  <button
                    style={divStyle}
                    type="button"
                    className="-m-2 ml-5 p-2 text-green-500 hover:text-green-600 sm:ml-7"
                    onClick={() => setButtonClicked(!buttonClicked)}
                  >
                    <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div aria-labelledby="products-heading" className="pb-24 pt-6">
              <div className="shadow-md overflow-hidden border border-gray-300 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      {headers.map((header, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-xs text-center font-bold text-gray-600 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Array.isArray(record) ? (
                      record.map((data, ind) => (
                        <tr
                          className={ind % 2 === 0 ? "bg-white" : "bg-gray-100"}
                          key={ind}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            {data.company}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                            {data.job}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                            {data.customId}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-light text-center">
                           
                            <button
                              disabled={update}
                              type="button"
                              className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-red-500"
                              onClick={() => handleDelete(data)}
                            >
                              NewOrder
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-center">
                          No blueprint data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}