import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EntryAsync,
  selectrotationActive,
} from "../features/record/recordSlice";

export default function EntryPage() {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("polyster");
  const [company, setcompany] = useState("");
  const [job, setjob] = useState("");
  const [size, setsize] = useState(0);
  const [mic, setmic] = useState(0);
  const [quantity, setquantity] = useState(0);
  const [date, setdate] = useState(new Date().toISOString().substr(0, 10));
  const [EType, setEType] = useState("Inward");
  const [emptyField, setEmptyField] = useState([]);
  const LodingStatus = useSelector(selectrotationActive);

  const handlesubmint = (e) => {
    e.preventDefault();

    const emptyFields = [
      { field: company.trim() === "", name: "Company" },
      { field: job.trim() === "", name: "Job" },
      { field: quantity === 0, name: "Quantity" },
      { field: size === 0, name: "Size" },
    ];

    const emptyFieldNames = emptyFields
      .filter((validation) => validation.field)
      .map((validation) => validation.name);

    if (emptyFieldNames.length > 0) {
      setEmptyField(emptyFieldNames);
      return;
    }
    const data = {
      company: company,
      job: job,
      size: size,
      mic: mic,
      quantity: quantity,
      date: date,
      type: EType,
    };
    dispatch(EntryAsync({ data, selectedType }));
    setnull();
  };

  const setnull= ()=>{
      setcompany("");
      setjob("");
      setSelectedType("polyster");
      setsize(0);
      setquantity(0);
      setmic(0);
  };
  return (
    <>
      <div className="isolate px-6 py-2 sm:py-1 lg:px-8">
        <form className="mx-auto mt-10 max-w-xl sm:mt-2">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Company
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setcompany(e.target.value)}
                  type="text"
                  id="company"
                  autoComplete="off"
                  value={company}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Company") && (
                  <p className="text-red-500 text-sm mt-1">
                    Company field is required.
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="job"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Job
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setjob(e.target.value)}
                  type="text"
                  id="job"
                  value={job}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {emptyField.includes("Job") && (
                  <p className="text-red-500 text-sm mt-1">
                    Job field is required.
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="size"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Size
              </label>
              <div className="mt-2.5">
                <input
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setsize(+e.target.value)}
                  type="number"
                  value={size === 0 ? "" : size}
                  id="size"
                />
                {emptyField.includes("Size") && (
                  <p className="text-red-500 text-sm mt-1">
                    Size field is required.
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="quantity"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Quantity
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setquantity(+e.target.value)}
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  type="number"
                  value={quantity === 0 ? "" : quantity}
                  id="quantity"
                />
                {emptyField.includes("Quantity") && (
                  <p className="text-red-500 text-sm mt-1">
                    Quantity field is required.
                  </p>
                )}
              </div>
            </div>
            {/* type */}
            <div>
              <label
                htmlFor="Type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Type
              </label>
              <div className="mt-2.5">
                <select
                  id="Type"
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                >
                  <option value="polyster">Polyster</option>
                  <option value="metal">Metal</option>
                  <option value="aluminium">Aluminium</option>
                  <option value="milky">Milky</option>
                  <option value="natural">Natural</option>
                  <option value="cppmetal">Cpp_Metal</option>
                  <option value="cppnatural">Cpp_Natural</option>
                </select>
              </div>
            </div>

            {/*  mic */}
            {selectedType === "milky" ||
            selectedType === "natural" ||
            selectedType === "cppmetal" ||
            selectedType === "cppnatural" ? (
              <div>
                <label
                  htmlFor="mic"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Mic
                </label>
                <div className="mt-2.5">
                  <input
                    id="mic"
                    onChange={(e) => setmic(+e.target.value)}
                  value={mic === 0 ? "" : mic}
                    className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    type="number"
                  />
                </div>
              </div>
            ) : null}

            {/* in/out  */}

            <div>
              <label
                htmlFor="EType"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Entry Type
              </label>
              <div className="mt-2.5">
                <select
                  id="EType"
                  className="block  w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => {
                    setEType(e.target.value);
                  }}
                >
                  <option value="Inward">Inward</option>
                  <option value="Outward">Outward</option>
                </select>
              </div>
            </div>

            {/* date  */}

            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Date
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(e) => setdate(e.target.value)}
                  type="date"
                  id="date"
                  value={date}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="button"
              onClick={handlesubmint}
              disabled={LodingStatus}
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {LodingStatus ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="font-medium ml-2">Processing...</span>{" "}
                </div>
              ) : (
                <>Add Entry</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
