import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {  setDataForAddOrder} from "../orderSlice";
import { changeNavbar } from "../../navbar/navbarSlice";

export default function OrderInfoPage({ data, isOpen, onClose }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const handleNewOrder = (e) => {
    dispatch(setDataForAddOrder(data));
    dispatch(changeNavbar("Entry"));
    navigate("/");
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, data]);

  return (
    <>
    <Transition.Root show={open} as={Fragment}>
  <Dialog
    as="div"
    className="relative z-10"
    initialFocus={cancelButtonRef}
    onClose={setOpen}
  >
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
            <div className="  px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="isolate px-6 py-2 sm:py-1 lg:px-8 ">
              <h2 className="text-2xl font-bold text-center mb-4 bg-gray-500 text-white py-2">Order Details</h2>
                <div className="mx-auto mt-10 max-w-xl sm:mt-2 ">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1 ">
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                              Company:
                            </p>
                            <p className="font-semibold text-xl">
                              {data.company}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                              Job:
                            </p>
                            <p className="font-semibold text-xl">{data.job}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Thickness:
                            </p>
                            <p className="font-semibold text-xl">{data.thickness}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Height:
                            </p>
                            <p className="font-semibold text-xl">{data.height}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Width:
                            </p>
                            <p className="font-semibold text-xl">{data.width}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Type:
                            </p>
                            <p className="font-semibold text-xl">{data.type}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Cylinderup:
                            </p>
                            <p className="font-semibold text-xl">{data.cylinderup}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Meter:
                            </p>
                            <p className="font-semibold text-xl">{data.meter}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Quantity:
                            </p>
                            <p className="font-semibold text-xl">{data.quantity} {data.InKg ? (<>KG</>):(<>K Nos</>)}</p>
                          </div>
                          {
                            data.InKg ? null : ( <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Pouch Type:
                            </p>
                            <p className="font-semibold text-xl">{data.pouchType}</p>
                          </div>)
                          }
                         <div className="flex items-center">
                            <p className="text-xl text-gray-500 font-semibold mr-2">
                            Date:
                            </p>
                            <p className="font-semibold text-xl">{data.date} </p>
                          </div>
                        </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md  bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                onClick={handleNewOrder}
              >
                Add new Order
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => onClose()}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>

    </>
  );
}
