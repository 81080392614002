import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageData: [
    { name: 'Entry', href: '/', current: false },
    { name: 'Order', href: '/orders', current: false },
    { name: 'Record', href: '/record', current: false },
    { name: 'Stock', href: '/stock', current: false },
    { name: 'CustomId', href: '/blueprint', current: false },
  ],
  status: "idle",
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    changeNavbar: (state, action) => {
      const { payload } = action;
      state.pageData = state.pageData.map(item => ({
        ...item,
        current: item.name === payload,
      }));
    },
  },
});

export const {  changeNavbar } = navbarSlice.actions;

export const selectPageData = state => state.navbar.pageData;

export default navbarSlice.reducer;
