import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { CompleteOrderAsync, UpdateOrderAsync } from "../orderSlice";

export default function CompletePage({ data, isOpen, onClose, eventType }) {
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [width, setWidth] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [polysterSize, setPolysterSize] = useState(0);
  const [polysterQuantity, setPolysterQuantity] = useState(0);
  const [metalSize, setMetalSize] = useState(0);
  const [metalQuantity, setMetalQuantity] = useState(0);
  const [ldSize, setLdSize] = useState(0);
  const [ldMic, setLdMic] = useState(0);
  const [ldQuantity, setLdQuantity] = useState(0);
  const [meter, setMeter] = useState(0);
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const handleUpdate = async (e) => {
    const record = {
      id: data.id,
      company: company,
      job: job,
      width: width,
      quantity: quantity,
      meter: meter,
      date: date,
      Tlayer: data.Tlayer,
      type: data.type,
      polysterSize: polysterSize,
      polysterQuantity: polysterQuantity,
      metalSize: metalSize,
      metalQuantity: metalQuantity,
      ldSize: ldSize,
      ldMic: ldMic,
      ldQuantity: ldQuantity,
    };
    if (eventType === "Complete") {
      
      dispatch(CompleteOrderAsync(record));
    }else{
      
      dispatch(UpdateOrderAsync(record));
    }
    onClose();
  };
  useEffect(() => {
    setCompany(data.company);
    setJob(data.job);
    setWidth(data.width);
    setQuantity(data.quantity);
    setPolysterSize(data.polysterSize);
    setPolysterQuantity(data.polysterQuantity);
    setMetalSize(data.metalSize);
    setMetalQuantity(data.metalQuantity);
    setLdSize(data.ldSize);
    setLdMic(data.ldMic);
    setLdQuantity(data.ldQuantity);
    setDate(data.date);
    setMeter(data.meter);
    setOpen(isOpen);
  }, [isOpen, data]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="isolate px-6 py-2 sm:py-1 lg:px-8 ">
                      <div className="mx-auto mt-10 max-w-xl sm:mt-2">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                          <div>
                            <label
                              htmlFor="company"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Company
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setCompany(e.target.value)}
                                type="text"
                                name="company"
                                id="company"
                                placeholder={data.company}
                                autoComplete="organization"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="job"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Job
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setJob(e.target.value)}
                                type="text"
                                name="job"
                                id="job"
                                placeholder={data.job}
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="width"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Width
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setWidth(+e.target.value)}
                                type="number"
                                name="width"
                                id="width"
                                placeholder={data.width}
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="quantity"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Quantity
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setQuantity(+e.target.value)}
                                type="number"
                                name="quantity"
                                id="quantity"
                                placeholder={
                                  data.quantity + (data.InKg ? " Kg" : "K")
                                }
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="meter"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Meter
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setMeter(+e.target.value)}
                                type="number"
                                name="meter"
                                id="meter"
                                placeholder={data.meter}
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="date"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Date
                            </label>
                            <div className="mt-2.5">
                              <input
                                onChange={(e) => setDate(e.target.value)}
                                type="date"
                                id="date"
                                value={date}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-1/3 pt-4">
                            <div className="block text-base text-center font-semibold leading-6 py-2 text-gray-900">
                              Polyser
                            </div>
                            <div className="justify-center items-center">
                              <label
                                htmlFor="Polsize"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                              >
                                Size
                              </label>
                              <div className="mt-2.5">
                                <input
                                  onChange={(e) =>
                                    setPolysterSize(+e.target.value)
                                  }
                                  type="number"
                                  name="Polsize"
                                  id="Polsize"
                                  placeholder={data.polysterSize}
                                  autoComplete="family-name"
                                  className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="Polquantity"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                              >
                                Quantity
                              </label>
                              <div className="mt-2.5">
                                <input
                                  onChange={(e) =>
                                    setPolysterQuantity(+e.target.value)
                                  }
                                  type="number"
                                  name="Polquantity"
                                  id="Polquantity"
                                  placeholder={data.polysterQuantity}
                                  autoComplete="family-name"
                                  className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                          {(data.Tlayer === "PAL" || data.Tlayer === "PML") &&(
                            <div className="w-1/3 pt-4">
                              <div className="block text-base text-center font-semibold leading-6 py-2 text-gray-900">
                              {data.Tlayer === "PAL" ? <>Aluminium</>:<>Metal</>}
                              </div>
                              <div className="justify-center items-center">
                                <label
                                  htmlFor="Metsize"
                                  className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                  Size
                                </label>
                                <div className="mt-2.5">
                                  <input
                                    onChange={(e) =>
                                      setMetalSize(+e.target.value)
                                    }
                                    type="number"
                                    name="Metsize"
                                    id="Metsize"
                                    autoComplete="family-name"
                                    placeholder={data.metalSize}
                                    className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="Metquantity"
                                  className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                  Quantity
                                </label>
                                <div className="mt-2.5">
                                  <input
                                    onChange={(e) =>
                                      setMetalQuantity(+e.target.value)
                                    }
                                    type="number"
                                    name="Metquantity"
                                    id="Metquantity"
                                    autoComplete="family-name"
                                    placeholder={data.metalQuantity}
                                    className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="w-1/3 pt-4">
                            <div className="block text-base text-center font-semibold leading-6 py-2 text-gray-900">
                              {data.type && data.type.charAt(0).toUpperCase() + data.type.slice(1)}
                            </div>
                            <div className="">
                              <label
                                htmlFor="ldsize"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                              >
                                Size
                              </label>
                              <div className="mt-2.5">
                                <input
                                  onChange={(e) => setLdSize(+e.target.value)}
                                  type="number"
                                  name="ldsize"
                                  id="ldsize"
                                  autoComplete="family-name"
                                  placeholder={data.ldSize}
                                  className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="ldMic"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                              >
                                Mic
                              </label>
                              <div className="mt-2.5">
                                <input
                                  onChange={(e) => setLdMic(+e.target.value)}
                                  type="number"
                                  name="ldMic"
                                  id="ldMic"
                                  placeholder={data.ldMic}
                                  autoComplete="family-name"
                                  className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="ldquantity"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                              >
                                Quantity
                              </label>
                              <div className="mt-2.5">
                                <input
                                  onChange={(e) =>
                                    setLdQuantity(+e.target.value)
                                  }
                                  type="ldquantity"
                                  name="ldquantity"
                                  id="ldquantity"
                                  placeholder={data.ldQuantity}
                                  autoComplete="family-name"
                                  className="block w-40 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md  bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      {eventType}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => onClose()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
