import React from "react";

export default function DataTable({ headers, data, size }) {
  return (
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
        <div className="py-4 align-middle inline-block min-w-full">
          {size && (
            <div className="text-2xl font-bold mb-4 text-gray-700">
              Size: {size}
            </div>
          )}
          <div className="shadow-md overflow-hidden border border-gray-300 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-200">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`px-6 py-3 text-center text-xs font-bold ${
                        header === "BAL QTY" ? "text-blue-400" : "text-gray-600"
                      } uppercase tracking-wider`}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    {row.map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        className={`px-6 py-4 whitespace-nowrap ${
                          cell < 0 ? "text-red-700" : "text-black"
                        } text-sm text-center ${
                          cellIndex === 4 ? "font-medium" : "font-light"
                        } `}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
