import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./features/navbar/navbar";
import StockPage from "./page/stock";
import RecordPage from "./page/record";
import OrderRecordPage from "./page/orderRecord";
import LoginPage from "./page/login";
import Protected from "./features/auth/protected";
import Home from "./page/home";
import { checkAuthAsync, selectUserChecked } from "./features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import PrintButton from "./features/order/components/PrintOrder";
import BlueprintRecordPage from "./page/BlueprintRecordPage";
// import Logout from "./features/auth/components/Logout";

const routes = [
  { path: "/login", element: <LoginPage /> },
  {
    path: "/print",
    element: (
      <Protected>
        <NavBar>
          <PrintButton />
        </NavBar>
      </Protected>
    ),
  },
  {
    path: "/",
    element: (
      <Protected>
        <NavBar>
          <Home />
        </NavBar>
      </Protected>
    ),
  },
  {
    path: "/stock",
    element: (
      <Protected>
        <NavBar>
          <StockPage />
        </NavBar>
      </Protected>
    ),
  },
  {
    path: "/record",
    element: (
      <Protected>
        <NavBar>
          <RecordPage />
        </NavBar>
      </Protected>
    ),
  },
  {
    path: "/blueprint",
    element: (
      <Protected>
        <NavBar>
          <BlueprintRecordPage />
        </NavBar>
      </Protected>
    ),
  },
  {
    path: "/orders",
    element: (
      <Protected>
        <NavBar>
          <OrderRecordPage />
        </NavBar>
      </Protected>
    ),
  },
];

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUserChecked);

  useEffect(() => {
    dispatch(checkAuthAsync());
  }, [dispatch]);

  return (
    <>
      {user && (
        <BrowserRouter>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
